import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  classes: [],
  classesForTable: [],
  class: null,
  createStatus: "",
  editStatus: "",
  deleteClassStatus: "",
  getClassByIdStatus: "",
  getClassesStatus: "",
  checkClassStatus: "",
};

const weekDaysOrder = {
  Sunday: 6,
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
};
// Function to check if two arrays are equal
function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i]._id !== arr2[i]._id) {
      return false;
    }
  }

  return true;
}
// Function to check if two classes arrays are equal
function areClassesEqual(classes1, classes2) {
  if (classes1.length !== classes2.length) {
    return false;
  }

  for (let i = 0; i < classes1.length; i++) {
    const day1 = classes1[i];
    const day2 = classes2.find((d) => d._id === day1._id);

    if (!day2 || !arraysAreEqual(day1.arr, day2.arr)) {
      return false;
    }
  }

  return true;
}

const sortByWeekDays = (a, b) => {
  const dayA = weekDaysOrder[a._id];
  const dayB = weekDaysOrder[b._id];

  return dayA - dayB;
};

export const getClassesForUser = createAsyncThunk(
  "classSlice/getClassesForUser",
  async (id, { rejectWithValue }) => {
    try {
      const url = await process.env.REACT_APP_BACKEND_MAIN_SERVER_URI;
      const response = await axios.get(`${url}/class/user/${id}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
    }
  }
);

export const getClassById = createAsyncThunk(
  "classSlice/getClassById",

  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/class/user/${params.userId}/classes/${params.classID}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
    }
  }
);

export const checkClass = createAsyncThunk(
  "classSlice/checkClass",

  async (classId, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/class/edit/check/${classId}`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In checkClass : ", error.message);
    }
  }
);

export const createNewClass = createAsyncThunk(
  "classSlice/createNewClass",
  async (newClass, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/class/create`,
        { ...newClass },
        {
          withCredentials: true,
        }
      );
      if (response.status === 201) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
    }
  }
);

export const deleteClass = createAsyncThunk(
  "classSlice/deleteClass",
  async (classId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/class/delete/${classId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
    }
  }
);

export const updateclass = createAsyncThunk(
  "classSlice/updateClass",
  async (changes, classId, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/edit/${classId}`,
        { ...changes },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
    }
  }
);
const classSlice = createSlice({
  name: "classSlice",
  initialState,
  reducers: {
    resetClassState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // get classes for user
    builder
      .addCase(getClassesForUser.pending, (state, action) => {
        return { ...state, getClassesStatus: "pending" };
      })
      .addCase(getClassesForUser.fulfilled, (state, action) => {
        if (action.payload) {
          const { classes } = action.payload;

          // Check if there are differences before updating
          if (!areClassesEqual(state.classes, classes)) {
            // Create a copy of the payload classes and sort them
            const classesCopy = Array.isArray(classes) ? [...classes] : [];

            classesCopy.sort(sortByWeekDays);

            return {
              ...state,
              classes: classesCopy,
              getClassByIdStatus: "success",
            };
          }
        }

        // If there are no differences, return the current state
        return state;
      })
      .addCase(getClassesForUser.rejected, (state, action) => {
        toast.error(action.error.message);

        return { ...state, getClassByIdStatus: "rejected" };
      });

    // create new class
    builder
      .addCase(createNewClass.pending, (state) => {
        state.createStatus = "pending";
      })
      .addCase(createNewClass.fulfilled, (state, action) => {
        if (action.payload) {
          const dayOfWeek = weekDaysOrder[action.payload.savedClass.dayOfWeek];
          state.classes[dayOfWeek] = state.classes[dayOfWeek] || {
            _id: action.payload.savedClass.dayOfWeek,
            arr: [],
          };
          state.classes[dayOfWeek].arr.push(action.payload.savedClass);
          state.createStatus = "success";

          toast.success("Class Created SuccessFully.");
        }
      })
      .addCase(createNewClass.rejected, (state, action) => {
        state.createStatus = "rejected";
        console.log(
          "Error Occured while creating new class : ",
          action.error.message
        );
        toast.error(action.error.message);
      });

    // get class by id for a user
    builder
      .addCase(getClassById.pending, (state, action) => {
        state.getClassByIdStatus = "pending";
      })
      .addCase(getClassById.fulfilled, (state, action) => {
        if (action.payload) {
          state.class = action.payload.classObject;
          state.getClassByIdStatus = "success";
        }
      })
      .addCase(getClassById.rejected, (state, action) => {
        console.log("Error In getting Class By ID : ", action.error.message);
        state.getClassByIdStatus = "rejected";
      });

    // delete class for user
    builder
      .addCase(deleteClass.pending, (state, action) => {
        return { ...state, deleteClassStatus: "pending" };
      })
      .addCase(deleteClass.fulfilled, (state, action) => {
        if (action.payload) {
          const arr = state.classes[
            weekDaysOrder[action.payload.deletedClass.dayOfWeek]
          ].arr?.filter((nigga) => {
            return nigga._id !== action.payload.deletedClass._id;
          });

          state.classes[
            weekDaysOrder[action.payload.deletedClass.dayOfWeek]
          ].arr = arr;

          state.deleteClassStatus = "success";

          toast.success("Class Deleted SuccessFully.");
        }
      })
      .addCase(deleteClass.rejected, (state, action) => {
        toast.error(action.error.message);
        state.deleteClassStatus = "rejected";
      });

    // check class
    builder
      .addCase(checkClass.pending, (state) => {
        state.checkClassStatus = "pending";
      })
      .addCase(checkClass.fulfilled, (state, action) => {
        if (action.payload) {
          const arr =
            state.classes[weekDaysOrder[action.payload.updatedClass.dayOfWeek]]
              .arr;

          for (let i = 0; i < arr.length; i++) {
            if (arr[i]._id === action.payload.updatedClass._id) {
              arr[i] = { ...arr[i], isChecked: !arr[i].isChecked };
              break;
            }
          }

          state.classes[
            weekDaysOrder[action.payload.updatedClass.dayOfWeek]
          ].arr = arr;
          state.checkClassStatus = "success";
        }
      })
      .addCase(checkClass.rejected, (state, action) => {
        toast.error(action.error.message);
        state.checkClassStatus = "rejected";
      });
  },
});
export const { resetClassState } = classSlice.actions;

export default classSlice.reducer;
