import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./Components/Home/Home";
import Layout from "./Components/Layout/Layout";
import Assignments from "./Components/Section_3/Assignment/Assignments";
import Exam from "./Components/Section_3/Exam/Exam";
import { useEffect, useState } from "react";
import store from "./redux/store/store";
import { checkLoginStatus } from "./redux/slice/usersSlice";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CoursePage from "./Components/Section_3/CoursePage/CoursePage";
import Courses from "./Components/Section_3/Course/Courses";
import ClassPage from "./Components/Section_3/Classes/ClassPage";
import ShinchanLoader from "./Components/Loader/ShinchanLoader";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import AuthError from "./Components/ErrorPage/AuthError";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
if (process.env.REACT_APP_NODE_ENV === "production") disableReactDevTools();

function App() {
  useEffect(() => {
    store.dispatch(checkLoginStatus(null));

    return () => {};
  }, []);

  const Loading = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      return () => clearTimeout(loadingTimeout);
    }, []);

    return isLoading ? <ShinchanLoader /> : children;
  };

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          newestOnTop
          closeOnClick
          draggable
          pauseOnHover
          theme="dark"
        />
        <Routes>
          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <Loading>
                  <Home />
                </Loading>
              }
            />
            <Route
              path="Assignments"
              element={
                <Loading>
                  <Assignments />
                </Loading>
              }
            />

            <Route
              path="Course"
              element={
                <Loading>
                  <Courses />
                </Loading>
              }
            />
            <Route
              path="Exams"
              element={
                <Loading>
                  <Exam />
                </Loading>
              }
            />

            <Route
              path="Classes"
              element={
                <Loading>
                  <ClassPage />
                </Loading>
              }
            />

            <Route path="course/:courseId" element={<CoursePage />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/AuthError" element={<AuthError />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
