import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import arraysEqual from "../../Components/Utilities/arrayEquals";

const initialState = {
  displayAssignMentsAndExams: [],
  getStatus: "",
};

export const getAssignMentsAndExamsForUser = createAsyncThunk(
  "calendarSlice/getAssignMentsAndExamsForUser",
  async () => {
    try {
      const url = await process.env.REACT_APP_BACKEND_MAIN_SERVER_URI;
      const response = await axios.get(
        `${url}/calendar/upcomingAndTodayAssignmentsAndExams`,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return response.data.message;
      }
    } catch (error) {
      console.log("Error In getAssingMentsForUser : ", error.message);
      return error.message;
    }
  }
);

const calendarSlice = createSlice({
  name: "calendarSlice",
  initialState,
  reducers: {
    resetCalendarState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // get classes for user
    builder
      .addCase(getAssignMentsAndExamsForUser.pending, (state) => {
        state.getStatus = "pending";
      })
      .addCase(getAssignMentsAndExamsForUser.fulfilled, (state, action) => {
        if (action.payload) {
          if (
            !arraysEqual(
              state.displayAssignMentsAndExams,
              action.payload?.allEvents
            )
          ) {
            state.displayAssignMentsAndExams = action.payload?.allEvents;
            state.getStatus = "success";
          }
        }
      })
      .addCase(getAssignMentsAndExamsForUser.rejected, (state, action) => {
        console.log(action.error.message);
        state.getStatus = "rejected";
      });
  },
});
export const { resetCalendarState } = calendarSlice.actions;

export default calendarSlice.reducer;
