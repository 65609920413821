import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  displayAssignMents: [],
  assingment: null,
  createStatus: "",
  editStatus: "",
  deleteStatus: "",
  getAssignMentByIdStatus: "",
  getAssignMentsStatus: "",
};

export const getAssignMentsForUser = createAsyncThunk(
  "assignMentSlice/getAssignMentsForUser",
  async (id, { rejectWithValue }) => {
    try {
      const url = await process.env.REACT_APP_BACKEND_MAIN_SERVER_URI;
      const response = await axios.get(`${url}/assignment/user/${id}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getAssingMentsForUser : ", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getAssignmentById = createAsyncThunk(
  "assignMentSlice/getAssignmentById",

  async ({ userId, assignmentId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/assignment/user/${userId}/${assignmentId}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
    }
  }
);
export const createNewAssignMent = createAsyncThunk(
  "assignMentSlice/createNewAssignMent",
  async (newAssignMent, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/assignment/create`,
        { ...newAssignMent },
        { withCredentials: true }
      );

      if (response.status === 201) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In creating new Assignment: ", error.message);
      return rejectWithValue("Failed to create assignment");
    }
  }
);

export const editAssignment = createAsyncThunk(
  "assignMentSlice/editAssignment",
  async ({ assignment, assignmentId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/assignment/edit/${assignmentId}`,
        assignment,
        {
          withCredentials: true,
        }
      );
      if (response.status === 201) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAssignment = createAsyncThunk(
  "assignMentSlice/deleteAssignment",
  async (assignmentId, { rejectWithValue }) => {
    try {
      console.log(assignmentId);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/assignment/delete/${assignmentId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
      return rejectWithValue(error.message);
    }
  }
);

const assignMentSlice = createSlice({
  name: "assignMentSlice",
  initialState,
  reducers: {
    resetAssState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // get classes for user
    builder
      .addCase(getAssignMentsForUser.pending, (state) => {
        state.getAssignMentByIdStatus = "pending";
      })
      .addCase(getAssignMentsForUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.displayAssignMents = action.payload.assignments;
          state.getAssignMentsStatus = "success";
        }
      })
      .addCase(getAssignMentsForUser.rejected, (state, action) => {
        console.log(action.error.message);
        state.getAssignMentByIdStatus = "rejected";
      });

    // get assignment by id for a user
    builder
      .addCase(getAssignmentById.pending, (state) => {
        state.getClassesStatus = "peonding";
      })
      .addCase(getAssignmentById.fulfilled, (state, action) => {
        if (action.payload) {
          state.assingment = action.payload.assignment;
          state.getAssignMentByIdStatus = "success";
        }
      })
      .addCase(getAssignmentById.rejected, (state, action) => {
        console.log(action.error.message);
        toast.error(action.error.message);
        state.getClassesStatus = "rejected";
      });

    // create new assignment
    builder
      .addCase(createNewAssignMent.pending, (state) => {
        state.createStatus = "pending";
      })
      .addCase(createNewAssignMent.fulfilled, (state, action) => {
        state.createStatus = "success";
        toast.success("Assignment Created Successfully.");
        if (action.payload) {
          state.displayAssignMents.push(action.payload.savedAssignment);
        }
      })
      .addCase(createNewAssignMent.rejected, (state, action) => {
        console.log(action.error.message);
        state.createStatus = "rejected";
      });

    // edit assignment
    builder
      .addCase(editAssignment.pending, (state) => {
        state.editStatus = "pending";
      })
      .addCase(editAssignment.fulfilled, (state, action) => {
        if (action.payload) {
          const newAss = state.displayAssignMents.map((ass, index) => {
            return ass._id === action.payload.updatedAssignment._id
              ? action.payload.updatedAssignment
              : ass;
          });

          toast.success("Assignment Updated SuccessFully.");
          return {
            ...state,
            displayAssignMents: newAss,
            editStatus: "success",
          };
        }
      })
      .addCase(editAssignment.rejected, (state, action) => {
        console.log(action.error.message);
        toast.error(action.error.message);
        state.editStatus = "rejected";
      });

    // delete assignment
    builder
      .addCase(deleteAssignment.pending, (state) => {
        state.deleteStatus = "pending";
      })
      .addCase(deleteAssignment.fulfilled, (state, action) => {
        if (action.payload) {
          const newAss = state.displayAssignMents.filter((ass) => {
            return ass._id !== action.payload.deletedAssignment._id;
          });

          toast.success("Assignment Deleted SuccessFully.");
          return {
            ...state,
            displayAssignMents: newAss,
            deleteStatus: "success",
          };
        }
      })
      .addCase(deleteAssignment.rejected, (state, action) => {
        console.log(action.error.message);
        toast.error(action.error.message);
        state.deleteStatus = "rejected";
      });
  },
});
export const { resetAssState } = assignMentSlice.actions;

export default assignMentSlice.reducer;
