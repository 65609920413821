import { useEffect, useState } from "react";
import "./Course.css";
import {
  MdAddCircleOutline,
  MdOutlineCollectionsBookmark,
} from "react-icons/md";

import ModalComponent from "../../Modal/ModalComponent";
import CourseModal from "../../CourseModal/courseModal";
import nbbg from "../../Assets/notebookbg.gif";
import Table from "../Table/Table";
import {
  deleteCourse,
  getCoursesForUser,
} from "../../../redux/slice/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import EditCourse from "./EditCourse";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

const Courses = () => {
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const { courses } = useSelector((store) => store.course);
  const openCourseModal = () => setIsCourseModalOpen(true);
  const closeModal = () => setIsCourseModalOpen(false);
  const { _id, isLoggedIn } = useSelector((store) => store.user);

  const dispatch = useDispatch();
  const data = courses || [];

  const handleDelete = (_id) => {
    dispatch(deleteCourse(_id));
  };

  useEffect(() => {
    if (_id && isLoggedIn) {
      dispatch(getCoursesForUser(_id));
    }
    window.scrollTo(0, 0);
    return () => {};
  }, [_id, dispatch, isLoggedIn]);

  const columns = [
    { field: "id", headerName: "ID", width: 200, hideAble: true },
    { field: "name", headerName: "Name", width: 200, hideAble: true },
    { field: "professor", headerName: "Professor", width: 200, hideAble: true },
    { field: "classroom", headerName: "Classroom", width: 200, hideAble: true },
    { field: "startDate", headerName: "StartDate", width: 200, hideAble: true },
    { field: "endDate", headerName: "EndDate", width: 200, hideAble: true },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 1,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      filterable: false,

      renderCell: (params) => (
        <div className="action_btns">
          <EditCourse id={params.row.id} />

          <button name="delete" onClick={() => handleDelete(params.row.id)}>
            Delete
          </button>
        </div>
      ),
    },
  ];

  const rows =
    data &&
    data.map((course) => {
      return {
        id: course._id,
        name: course.name,
        professor: course.professor,
        classroom: course.classroom,
        startDate: new Date(course.startDate)?.toLocaleString([], {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",

          hour12: true,
        }),
        endDate: new Date(course.endDate)?.toLocaleString([], {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",

          hour12: true,
        }),
      };
    });
  if (!isLoggedIn) {
    toast.warning("User Not Logged In!");
    return <Navigate to={"/"} />;
  }
  return (
    <div>
      <div className="course_banner">
        <img src={nbbg} alt="" />
      </div>

      <div className="courses_content">
        <div className="course_header">
          <div className="main_title">
            <MdOutlineCollectionsBookmark />
            <p>Courses</p>
          </div>
          <div className="add_assign" onClick={openCourseModal}>
            <MdAddCircleOutline />
            <p>Add Course</p>
          </div>
        </div>
        <div>
          <Table rows={rows} columns={columns} />
        </div>
        <ModalComponent isOpen={isCourseModalOpen} onRequestClose={closeModal}>
          <CourseModal closeModal={closeModal} />
        </ModalComponent>
      </div>
    </div>
  );
};

export default Courses;
