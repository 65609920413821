const daysAndEmojis = [
  {
    emoji: "🍵",
    day: "Monday",
  },
  {
    emoji: "🍃",
    day: "Tuesday",
  },
  {
    emoji: "🌴",
    day: "Wednesday",
  },
  {
    emoji: "🌱",
    day: "Thursday",
  },
  {
    emoji: "🍀",
    day: "Friday",
  },
  {
    emoji: "🥝",
    day: "Saturday",
  },
  {
    emoji: "🌿",
    day: "Sunday",
  },
];

module.exports = daysAndEmojis;
