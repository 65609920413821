import { configureStore } from "@reduxjs/toolkit";
import users from "../slice/usersSlice";
import courseSlice from "../slice/courseSlice";
import classSlice from "../slice/classSlice";
import assignmentSlice from "../slice/assignmentSlice";
import examSlice from "../slice/examSlice";
import calendarSlice from "../slice/calendarSlice";

const store = configureStore({
  reducer: {
    user: users,
    course: courseSlice,
    class: classSlice,
    assignment: assignmentSlice,
    exam: examSlice,
    calendar: calendarSlice,
  },
  devTools: false,
});

export default store;
