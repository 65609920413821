import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editExam } from "../../redux/slice/examSlice";

const UpdateExamModal = ({ closeModal, selectedExam }) => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const { courses } = useSelector((store) => store.course);

  const dispatch = useDispatch();

  const [exam, setExam] = useState(
    selectedExam || {
      name: "",
      note: "",
      examDate: "2007-04-05T11:11",
      courseId: "",
      grade: "",
      reminder: false,
    }
  );

  const HandleExamEdit = () => {
    if (isLoggedIn) {
      const requiredFields = ["name", "examDate"];
      const missingFields = requiredFields.filter(
        (field) => exam[field]?.trim() === ""
      );

      if (missingFields.length === 0) {
        // Handle Redux logic for adding an exam
        dispatch(editExam({ exam, examId: selectedExam._id }));
        closeModal();

        setExam({
          name: "",
          note: "",
          examDate: "2007-04-05T11:11",
          courseId: "",
          grade: "",
          reminder: false,
        });
      } else {
        const missingFieldsMsg = `Missing fields: ${missingFields.join(", ")}`;
        toast.error(missingFieldsMsg);
      }
    } else {
      toast.error("Please Login to continue using matcha home");
    }
  };

  const HandleInputChange = (e) => {
    setExam((prev) => {
      return {
        ...prev,
        [e.target.name === "reminder" ? "reminder" : e.target.name]:
          e.target.name === "reminder" ? !prev.reminder : e.target.value,
      };
    });
  };

  // =======
  const oldExamDate = exam.examDate;

  //  622a95e8cc80d5f052397f0979fbbc46c75e35b2
  return (
    <div>
      <div className="events_header">
        <h2>Edit Exam</h2>

        <div className="func_btns">
          <button onClick={HandleExamEdit}>Edit Exam</button>

          <button onClick={closeModal}>Cancel</button>
        </div>
      </div>
      <label>
        Exam Name:
        <input
          type="text"
          placeholder="Enter Exam Name"
          value={exam.name}
          name="name"
          onChange={HandleInputChange}
          required
        />
      </label>
      <label>
        Note:
        <input
          type="text"
          placeholder="Enter Exam Note"
          value={exam.note}
          name="note"
          onChange={HandleInputChange}
        />
      </label>
      <label>
        Prev Exam Date:
        <input disabled type="datetime-local" value={oldExamDate} required />
      </label>
      <label>
        New Exam Date:
        <input
          type="datetime-local"
          value={exam.examDate}
          onChange={HandleInputChange}
          name="examDate"
          required
        />
      </label>
      <label>
        Course:
        <select
          name="courseId"
          value={exam.courseId}
          onChange={HandleInputChange}
        >
          <option value={""}>
            Select A Course Related To this assignment.{" "}
          </option>
          {courses.length !== 0 ? (
            courses.map((course, index) => (
              <option key={index} value={course._id}>
                {course.name}
              </option>
            ))
          ) : (
            <option disabled value={""}>
              You Have'nt Created Any Courses Yet
            </option>
          )}
        </select>
      </label>
      <label>
        Grade:
        <input
          type="text"
          placeholder="Enter Exam Grade"
          value={exam.grade}
          onChange={HandleInputChange}
          name="grade"
        />
      </label>
      <label className="rem_label">
        Reminder:
        <input
          type="checkbox"
          checked={exam.reminder}
          onChange={HandleInputChange}
          name="reminder"
          required
        />
      </label>
    </div>
  );
};

export default UpdateExamModal;
