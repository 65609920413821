import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./Calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import assignments from "../Assets/assignments.png";
import { MdAddCircleOutline } from "react-icons/md";
import ModalComponent from "../Modal/ModalComponent";
import { Link } from "react-router-dom";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import AssignmentModal from "../AssignmentModal/AssignmentModal";
import { useDispatch, useSelector } from "react-redux";
import memoize from "memoize-one";
import { getAssignMentsAndExamsForUser } from "../../redux/slice/calendarSlice";

const localizer = momentLocalizer(moment);
const customViews = {
  month: true,
  agenda: true,
};

const MyCalendar = () => {
  const dispatch = useDispatch();

  const { _id, isLoggedIn } = useSelector((store) => store.user);
  const { displayAssignMentsAndExams } = useSelector((store) => store.calendar);
  const [allEvents, setAllEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAssignMentsAndExamsForUser());
    }

    return () => {};
  }, [dispatch, _id, isLoggedIn]);

  useEffect(() => {
    setAllEvents(displayAssignMentsAndExams);
  }, [displayAssignMentsAndExams]);

  const memoizedEventStyleGetter = memoize((event, start, end, isSelected) => {
    let backgroundColor = "transparent";
    let color = "white";

    if (event.type === "Exam") {
      backgroundColor = "#492f64";
    } else {
      backgroundColor = "#69314c";
    }

    const style = {
      backgroundColor,
      color,
      borderRadius: "0px",
      display: "block",
      textAlign: "left",
    };

    return { style };
  });

  return (
    <div className="myCustomHeight">
      <div className="calendar_header">
        <div className="calendar_title">
          <p>
            <Link to="/Assignments">
              <img src={assignments} alt="" /> Assignments
            </Link>
          </p>
        </div>
        <div className="addevent">
          <button onClick={openModal}>
            <MdAddCircleOutline />
            Add Assignment
          </button>
        </div>
      </div>
      <Calendar
        localizer={localizer}
        views={customViews}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={memoizedEventStyleGetter}
        style={{ height: "100%" }}
      />

      <ModalComponent isOpen={isModalOpen} onRequestClose={closeModal}>
        <AssignmentModal closeModal={closeModal} />
      </ModalComponent>
    </div>
  );
};

export default MyCalendar;
