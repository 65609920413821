import "./Table.css";

import { DataGrid } from "@mui/x-data-grid";

const Table = ({ rows, columns }) => {
  return (
    <div>
      <div style={{ height: 400, width: "100%", color: "snow" }}>
        {rows && columns && (
          <DataGrid
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            columnVisibilityModel={{
              id: false,
            }}
            pageSizeOptions={[5, 10, 25]}
            // pageSize={5}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
