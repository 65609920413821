import { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import ModalComponent from "../../Modal/ModalComponent";
import exam from "../../Assets/exam.gif";
import "./Exam.css";
import Table from "../Table/Table";
import ExamModal from "../../ExamModal/ExamModal";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesForUser } from "../../../redux/slice/courseSlice";
import { deleteExam, getExamsForUser } from "../../../redux/slice/examSlice";
import { PiExam } from "react-icons/pi";
import getColorForCourseId from "../../Utilities/getColorsForCourseId";

import EditExam from "./EditExam/EditExam";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

const Exam = () => {
  const { displayExams } = useSelector((store) => store.exam);
  const { _id, isLoggedIn } = useSelector((store) => store.user);

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (_id && isLoggedIn) {
      dispatch(getCoursesForUser(_id));
    }
    return () => {};
  }, [_id, dispatch, isLoggedIn]);

  useEffect(() => {
    if (_id && isLoggedIn) {
      dispatch(getExamsForUser(_id));
    }
    window.scrollTo(0, 0);
    return () => {};
  }, [_id, dispatch, isLoggedIn]);

  const data = displayExams || [];

  const rows =
    data &&
    data.map((exam) => {
      return {
        id: exam._id,
        name: exam.name,
        course: exam.courseId ? exam.courseId.name : "N/A",
        examDate: new Date(exam.examDate).toLocaleString([], {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        }),
        grade: exam.grade,
        note: exam.note,
        reminder: exam.reminder,
      };
    });

  console.log(rows[1]?.course);
  const handleDelete = (_id) => {
    dispatch(deleteExam(_id));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "examDate", headerName: "ExamDate", width: 200 },
    {
      field: "course",
      headerName: "Course",
      width: 150,
      renderCell: (params) => (
        <div
          style={
            params.row.course === "N/A" || !params.row.course
              ? {}
              : {
                  backgroundColor: getColorForCourseId(params.row.course),
                  borderRadius: "5px",
                  padding: "0.3em",
                }
          }
        >
          {params.row.course ? params.row.course : "N/A"}
        </div>
      ),
    },
    { field: "grade", headerName: "Grade", width: 120 },
    { field: "note", headerName: "Note", width: 120 },

    {
      field: "reminder",
      headerName: "Reminder",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor: getColorForCourseId(
              params.row.reminder === true ? "ON" : "OFF"
            ),
            borderRadius: "5px",
            padding: "0.2em",
          }}
        >
          <p>{params.row.reminder ? "ON" : "OFF"}</p>
        </div>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 1,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      filterable: false,

      renderCell: (params) => (
        <div className="action_btns">
          <EditExam id={params.row.id} />
          <button name="delete" onClick={() => handleDelete(params.row.id)}>
            Delete
          </button>
        </div>
      ),
    },
  ];
  if (!isLoggedIn) {
    toast.warning("User Not Logged In!");
    return <Navigate to={"/"} />;
  }
  return (
    <div>
      <div className="exam_banner">
        <img src={exam} alt="exam-banner" />
      </div>
      <div className="exam_content">
        <div className="exam_header">
          <div className="main_title">
            <PiExam />
            <p>Exams</p>
          </div>

          <div className="add_assign" onClick={openModal}>
            <MdAddCircleOutline />
            <p>Add Exam</p>
          </div>
        </div>

        {rows && columns && (
          <div className="table">
            <Table rows={rows} columns={columns} />
          </div>
        )}

        <ModalComponent isOpen={isModalOpen} onRequestClose={closeModal}>
          <ExamModal closeModal={closeModal} />
        </ModalComponent>
      </div>
    </div>
  );
};

export default Exam;
