import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateCourse } from "../../redux/slice/courseSlice";

const UpdateCourseModal = ({ closeModal, selectedCourse }) => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [course, setCourse] = useState(
    selectedCourse || {
      name: "",
      professor: "",
      classroom: "",
      startDate: "2007-04-05",
      endDate: "2007-04-05",
    }
  );

  const prevStartDate = selectedCourse.startDate.split("T")[0];
  const prevEndDate = selectedCourse.endDate.split("T")[0];

  const HandleInputChange = (e) => {
    setCourse((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleEditCourse = () => {
    if (isLoggedIn) {
      if (course.name.trim() !== "" && course.professor.trim() !== "") {
        dispatch(updateCourse({ course, courseId: selectedCourse._id }));

        closeModal();
        setCourse({
          name: "",
          professor: "",
          classroom: "",
          startDate: "2007-05-05",
          endDate: "2007-05-05",
        });
      } else {
        let missingFields = [];
        if (course.name.trim() === "") {
          missingFields.push("Course Name");
        }
        if (course.professor.trim() === "") {
          missingFields.push("Professor's Name");
        }
        const missingFieldsMsg = `Missing fields: ${missingFields.join(", ")}`;
        toast.error(missingFieldsMsg);
      }
    } else {
      toast.error(
        'User"s not logged in! Please Login to continue using matcha home'
      );
    }
  };

  return (
    <div>
      <div className="events_header">
        <h2>Edit Course</h2>
        <div className="func_btns">
          <button onClick={handleEditCourse}>Edit Course</button>
          <button onClick={closeModal}>Cancel</button>
        </div>
      </div>
      <label>
        Name:
        <input
          type="text"
          placeholder="name"
          name="name"
          required
          value={course.name}
          onChange={HandleInputChange}
        />
      </label>
      <label>
        Professor Name:
        <input
          type="text"
          placeholder="Professor"
          name="professor"
          value={course.professor}
          onChange={HandleInputChange}
        />
      </label>
      <label>
        Classroom:+
        <input
          type="text"
          placeholder="Classroom"
          name="classroom"
          value={course.classroom}
          onChange={HandleInputChange}
        />
      </label>

      <div className="time">
        <label>
          New Start Date:
          <input
            type="date"
            name="startDate"
            placeholder="Start Date"
            value={course.startDate}
            onChange={HandleInputChange}
          />
        </label>
        <label>
          New End Date:
          <input
            type="date"
            placeholder="End Date"
            name="endDate"
            value={course.endDate}
            onChange={HandleInputChange}
          />
        </label>
      </div>
      <div className="time">
        <label>
          Old Start Date:
          <input disabled type="date" value={prevStartDate} />
        </label>
        <label>
          Old End Date:
          <input disabled type="date" value={prevEndDate} />
        </label>
      </div>
    </div>
  );
};

export default UpdateCourseModal;
