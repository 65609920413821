import "./Section_2.css";
import img1 from "../Assets/img_1.jpg";
import img2 from "../Assets/shinchan-cover-img.JPG";

import Spotify from "./Spotify";
import MyCalendar from "../Calendar/Calendar";

import Goals from "./Goals";
import Inspirations from "./Inspirations";
import BrainDump from "./BrainDump";

const Section2 = () => {
  return (
    <div className="section-2">
      <h1>To do List</h1>
      <div className="grid_container_1">
        <div className="grid_item_1 todo_left">
          <div className="todolist_img">
            <img src={img1} alt="main-img" />
          </div>
          <div className="labels">
            <p>Assignments</p>
            <p>Exams</p>
          </div>
        </div>
        <div className="grid_item_1">
          <MyCalendar />
        </div>
      </div>
      <div className="grid_container_2">
        <div className="grid_item_2">
          <div className="remember">
            <h1>Remember</h1>
            <div className="remember_banner">
              <img src={img2} alt="" />
            </div>
          </div>
        </div>
        <div className="grid_item_2 gbd">
          <div className="goals">
            <Goals />
          </div>
          <div className="brain_dump">
            <BrainDump />
          </div>
        </div>
        <div className="grid_item_2 inspo">
          <div className="inspiration">
            <Inspirations />
          </div>
          <div className="spotify">
            <Spotify />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
