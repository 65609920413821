import { useState } from "react";
import { useSelector } from "react-redux";
import ModalComponent from "../../Modal/ModalComponent";
import UpdateCourseModal from "../../CourseModal/UpdateCourseModal";

const EditCourse = ({ id }) => {
  const { courses } = useSelector((store) => store.course);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);

  const [course, setCourse] = useState({
    name: "",
    professor: "",
    classroom: "",
    startDate: "2007-04-05",
    endDate: "2007-04-05",
  });

  const openEditModal = () => {
    const selectedCourse = courses?.find((course) => {
      return course._id === id;
    });

    if (selectedCourse) {
      setCourse((prev) => ({
        ...selectedCourse,
      }));
    }
    setIsCourseModalOpen(true);
  };
  const closeEditModal = () => setIsCourseModalOpen(false);

  return (
    <div>
      <button onClick={openEditModal}>Edit</button>
      <ModalComponent
        isOpen={isCourseModalOpen}
        onRequestClose={closeEditModal}
      >
        <UpdateCourseModal
          closeModal={closeEditModal}
          selectedCourse={course}
        />
      </ModalComponent>
    </div>
  );
};

export default EditCourse;
