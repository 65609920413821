import { useEffect } from "react";
import { BiCheckbox, BiSolidCheckboxChecked } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import daysAndEmojis from "../Utilities/daysAndEmojis";
import { useDispatch, useSelector } from "react-redux";
import {
  checkClass,
  deleteClass,
  getClassesForUser,
} from "../../redux/slice/classSlice";
import { getCoursesForUser } from "../../redux/slice/courseSlice";

const ClassOnDays = ({
  enableStrikethrough,
  strikethroughClass,
  additionalClass,
}) => {
  const { _id, isLoggedIn } = useSelector((state) => state.user);
  const { classes } = useSelector((state) => state.class);

  const dispatch = useDispatch();

  useEffect(() => {
    // Check if _id or isLoggedIn has changed before making the API request
    if (_id && isLoggedIn) {
      dispatch(getClassesForUser(_id));
    }

    if (_id && isLoggedIn) {
      dispatch(getCoursesForUser(_id));
    }
  }, [_id, isLoggedIn, dispatch]);

  const HandleCheck = (classId) => {
    dispatch(checkClass(classId));
  };

  const handleDeleteClass = (classId) => {
    dispatch(deleteClass(classId));
  };

  return (
    <>
      {daysAndEmojis?.map((dayData, dayIndex) => (
        <div className="day_card" key={dayIndex}>
          <div>
            <div className="emoji">
              <span>{dayData.emoji}</span>
              <h2> {dayData.day}</h2>
            </div>

            {classes[dayIndex] ? (
              classes[dayIndex]?.arr && classes[dayIndex]?.arr.length > 0 ? (
                classes[dayIndex].arr.map((ele, eleIndex) => {
                  return (
                    <div key={eleIndex}>
                      <div
                        className={
                          enableStrikethrough && ele.isChecked
                            ? `${strikethroughClass} `
                            : `${additionalClass} `
                        }
                      >
                        <div
                          className="todo_checkbox"
                          onClick={() => {
                            HandleCheck(ele._id);
                          }}
                        >
                          <div className="task">
                            {ele.isChecked ? (
                              <BiSolidCheckboxChecked />
                            ) : (
                              <BiCheckbox />
                            )}

                            <p>
                              {ele.startTime} - {ele.name} - {ele.endTime}
                            </p>
                          </div>
                        </div>
                        {additionalClass && (
                          <div className="delete_btn">
                            <button onClick={() => handleDeleteClass(ele._id)}>
                              <MdDelete />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p> No Classes for Today.</p>
              )
            ) : (
              <p> No Classes for Today.</p>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ClassOnDays;
