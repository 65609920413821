import { useState, useEffect } from "react";
import { MdAddCircleOutline, MdDelete } from "react-icons/md";
import ModalComponent from "../Modal/ModalComponent";
import "./Section_2.css";
import { FaArrowTurnUp } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Inspirations = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const [inspirations, setInspirations] = useState([]);
  const [newInspiration, setNewInspiration] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAddedDiv, setShowAddedDiv] = useState(true);

  useEffect(() => {
    if (isLoggedIn) {
      const storedInspirations =
        JSON.parse(localStorage.getItem("inspirations")) || [];
      setInspirations(storedInspirations);
      setShowAddedDiv(storedInspirations.length === 0);
    } else {
      setInspirations([]);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.setItem("inspirations", JSON.stringify(inspirations));
    setShowAddedDiv(inspirations.length === 0);
  }, [inspirations]);

  const handleAddInspiration = () => {
    if (isLoggedIn) {
      if (newInspiration.trim() !== "" && inspirations.length === 0) {
        setInspirations([newInspiration]);
        setNewInspiration("");
        closeModal();
        setShowAddedDiv(false);
      }
    } else {
      toast.error("Please Login to continue using matcha home");
    }
  };

  const handleDeleteInspiration = (index) => {
    const updatedInspirations = inspirations.filter((_, i) => i !== index);
    setInspirations(updatedInspirations);
    setShowAddedDiv(updatedInspirations.length === 0);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const isAddButtonDisabled = inspirations.length > 0;

  return (
    <div>
      <h1>
        Inspiration
        {!isAddButtonDisabled && (
          <button
            className="add_inspirations"
            onClick={openModal}
            disabled={isAddButtonDisabled}
          >
            <MdAddCircleOutline />
          </button>
        )}
      </h1>
      {showAddedDiv && (
        <div className="box add_btn">
          <p>
            Add Your Inspiration{" "}
            <span>
              <FaArrowTurnUp />
            </span>
          </p>
        </div>
      )}
      <div className="inspiration_scroll">
        {inspirations.map((inspiration, index) => (
          <div key={index} className="box">
            <p>{inspiration}</p>
            <button onClick={() => handleDeleteInspiration(index)}>
              <MdDelete />
            </button>
          </div>
        ))}
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Inspiration Modal"
      >
        <div className="events_header">
          <h2>Add Inspiration</h2>
        </div>
        <input
          type="text"
          placeholder="Enter new inspiration"
          value={newInspiration}
          onChange={(e) => setNewInspiration(e.target.value)}
        />
        <div className="func_btns">
          <button
            className="inspirations_button"
            onClick={handleAddInspiration}
          >
            Add Inspiration
          </button>
          <button className="inspirations_button" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default Inspirations;
