import Section2 from "../Section_2/Section2";
import Pages from "../Section_3/Pages/Pages";
import "./Home.css";
import Section1 from "../Section-1/Section1";

const Home = () => {
  return (
    <div className="home">
      <Section1 />
      <Section2 />
      <Pages />
    </div>
  );
};

export default Home;
