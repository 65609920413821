import { useEffect } from "react";
import Table from "../../Table/Table";
import { FaCircle } from "react-icons/fa";
import getDeterministicColorForCourseId from "../../../Utilities/getColorsForCourseId";
import { useDispatch, useSelector } from "react-redux";
import { getAssignmentsAndExams } from "../../../../redux/slice/courseSlice";

const CourseAndAssTable = ({ courseId }) => {
  const { assignmentsAndExams } = useSelector((store) => store.course);
  const { isLoggedIn } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (courseId && isLoggedIn) {
      dispatch(getAssignmentsAndExams(courseId));
    }
    return () => {};
  }, [courseId, dispatch, isLoggedIn]);

  const data = assignmentsAndExams || [];

  const columns = [
    { field: "id", headerName: "ID", width: 200, hideAble: true },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "type",
      headerName: "Type",
      width: 120,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor: getDeterministicColorForCourseId(params.row.type),
            borderRadius: "5px",
            padding: "0.2em",
          }}
        >
          {params.row.type}
        </div>
      ),
    },

    { field: "Date", headerName: "Date", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 150,

      renderCell: (params) => (
        <div className={params.row.status?.replace(" ", "")}>
          {params.row.status ? <FaCircle size={30} /> : ""}

          {params.row.status}
        </div>
      ),
    },
    { field: "note", headerName: "Note", width: 200 },
    { field: "grade", headerName: "Grade", width: 120 },
    {
      field: "reminder",
      headerName: "Reminder",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <div>
          <p>{params.row.reminder ? "ON" : "OFF"}</p>
        </div>
      ),
    },
  ];

  const rows =
    data &&
    data.map((ele) => {
      return {
        id: ele._id,
        name: ele.name,
        type: ele.type,

        Date: new Date(
          ele.type === "Exam" ? ele.examDate : ele.dueDate
        ).toLocaleString([], {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",

          hour12: true,
        }),
        status: ele.status,
        note: ele.note,
        grade: ele.grade,
        reminder: ele.reminder,
      };
    });

  return (
    <div className="course_table">
      <h2>Course Assignments & Exams</h2>
      <Table rows={rows} columns={columns} />
    </div>
  );
};

export default CourseAndAssTable;
