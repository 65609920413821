import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
const initialState = {
  _id: "",
  email: "",
  username: "",
  profileImg: "",
  isLoggedIn: false,
  status: "",
};

function areStatesEqual(state1, state2) {
  // Compare your state structure and return true if they are equal
  // You can use a library like Lodash's isEqual or implement a custom comparison
  // For simplicity, we'll just check equality of isLoggedIn, _id, and email
  return (
    state1.isLoggedIn === state2.isLoggedIn &&
    state1._id === state2._id &&
    state1.email === state2.email &&
    state1.username === state2.username &&
    state1.profileImg === state2.profileImg
  );
}

export const checkLoginStatus = createAsyncThunk(
  "userSlice/checkLoginStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_AUTH_SERVER_URI}/auth/check-login-status`,
        { withCredentials: true }
      );
      // If the request is successful, the user is logged in
      if (response.status === 200) {
        return response.data;
      } else {
        rejectWithValue(response.data.message);
      }
    } catch (error) {
      // If the request fails, the user is not logged in
      console.log("Error Occured in Check Login Status : ", error.message);
    }
  }
);

export const logout = createAsyncThunk(
  "userSlice/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_AUTH_SERVER_URI}/logout`,
        {
          withCredentials: true,
        }
      );
      // If the request is successful, the user is logged in
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      // If the request fails, the user is not logged in
      rejectWithValue(error.message);
      console.log("Error Occured in Check Login Status : ", error.message);
    }
  }
);

const users = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkLoginStatus.pending, (state, action) => {
        state.isLoggedIn = false;
        state.status = "pending";
      })
      .addCase(checkLoginStatus.fulfilled, (state, action) => {
        if (action.payload) {
          // Only update the state if there's a change in data
          if (!areStatesEqual(state, action.payload)) {
            return {
              ...state,
              ...action.payload,
              isLoggedIn: true,
              status: "success",
            };
          }
        }
        return state;
      })
      .addCase(checkLoginStatus.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.status = "rejected";
        console.log(action.error.message);
      });

    builder
      .addCase(logout.pending, (state, action) => {
        state.isLoggedIn = false;
        state.status = "pending";
      })
      .addCase(logout.fulfilled, (state, action) => {
        if (action.payload?.message) {
          toast.success("Logged Out SuccessFully!");
        }

        return {
          _id: "",
          email: "",
          username: "",
          profileImg: "",
          isLoggedIn: false,
          status: "",
        };
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "rejected";
        console.log(action.error.message);
        toast.error("Failed To Logout. Please Try Again!");
      });
  },
});

export default users.reducer;
