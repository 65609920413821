import React from "react";
import "./ErrorPage.css";

const ErrorPage = () => {
  return (
    <div className="error_page">
      <div className="hollowtext">
        ERROR 404 <hr /> <span>Page Not Found</span>
      </div>

      {/* <div className="mail">
          <h1>Matcha Home</h1>
  
          <p>Hello Bhadve,</p>
          <p>Your Assignments and Exams are here</p>
  
          <h2>Assignments</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Date&Time</th>
                <th>Course</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>John Doe</td>
                <td>Assignment</td>
                <td>2023-01-01 10:00 AM</td>
                <td>Mathematics</td>
                <td>Completed</td>
              </tr>
              <tr>
                <td>Jane Smith</td>
                <td>Exam</td>
                <td>2023-02-15 2:30 PM</td>
                <td>Science</td>
                <td>Pending</td>
              </tr>
            </tbody>
          </table>
          <h2>Exams</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date&Time</th>
                <th>Course</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>John Doe</td>
                <td>2023-01-01 10:00 AM</td>
                <td>Assignment</td>
                <td></td>
              </tr>
              <tr>
                <td>Jane Smith</td>
                <td>2023-02-15 2:30 PM</td>
                <td>Exam </td>
  
                <td>Pending</td>
              </tr>
            </tbody>
          </table>
  
          <button>Click here to visit Matcha Home</button>
          <hr />
  
          <p className="mail_footer">Thank you for choosing our services! we appreciate your business.</p>
          <p>Founder: BrainyNik</p>
          <p>CEO: Shivam</p>
        </div> */}
    </div>
  );
};

export default ErrorPage;
