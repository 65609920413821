import React from "react";

const Spotify = React.memo(() => {
  return (
    <div>
      <iframe
        title="matcha-home-spotify"
        style={{ borderRadius: "12px" }}
        src="https://open.spotify.com/embed/playlist/1JLw7Y5YvlsA10XjaKHTxE?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  );
});

export default Spotify;
