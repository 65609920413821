import shinchan from "../Assets/shinchan_loader.gif";

const ShinchanLoader = () => {
  return (
    <div className="loader">
      <div>
        <p>Loading...</p>
        <div className="loader_img">
          <img src={shinchan} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ShinchanLoader;
