import { useState, useEffect } from "react";
import { MdAddCircleOutline, MdDelete } from "react-icons/md";
import ModalComponent from "../Modal/ModalComponent";
import "./Section_2.css";
import { FaArrowTurnUp } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const BrainDump = () => {
  const { isLoggedIn } = useSelector((state) => state.user);

  const [braindumpItems, setBraindumpItems] = useState([]);
  const [newBraindumpItem, setNewBraindumpItem] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAddedDiv, setShowAddedDiv] = useState(true);

  useEffect(() => {
    if (isLoggedIn) {
      const storedBraindumpItems =
        JSON.parse(localStorage.getItem("braindumpItems")) || [];
      setBraindumpItems(storedBraindumpItems);
      setShowAddedDiv(storedBraindumpItems.length === 0);
    } else {
      setBraindumpItems([]);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.setItem("braindumpItems", JSON.stringify(braindumpItems));
    setShowAddedDiv(braindumpItems.length === 0);
  }, [braindumpItems]);

  const handleAddBraindumpItem = () => {
    if (isLoggedIn) {
      if (newBraindumpItem.trim() !== "" && braindumpItems.length === 0) {
        setBraindumpItems([newBraindumpItem]);
        setNewBraindumpItem("");
        closeModal();
        setShowAddedDiv(false);
      }
    } else {
      toast.error("Please Login to continue using matcha home");
    }
  };

  const handleDeleteBraindumpItem = (index) => {
    const updatedBraindumpItems = braindumpItems.filter((_, i) => i !== index);
    setBraindumpItems(updatedBraindumpItems);
    setShowAddedDiv(updatedBraindumpItems.length === 0);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const isAddButtonDisabled = braindumpItems.length > 0;

  return (
    <div>
      <h1>
        Braindump
        {!isAddButtonDisabled && (
          <button
            className="add_braindump"
            onClick={openModal}
            disabled={isAddButtonDisabled}
          >
            <MdAddCircleOutline />
          </button>
        )}
      </h1>
      {showAddedDiv && (
        <div className="box add_btn">
          <p>
            Add Brain Dump{" "}
            <span>
              <FaArrowTurnUp />
            </span>
          </p>
        </div>
      )}
      <div className="braindump_scroll">
        {braindumpItems.map((item, index) => (
          <div key={index} className="box">
            <p>{item}</p>
            <button onClick={() => handleDeleteBraindumpItem(index)}>
              <MdDelete />
            </button>
          </div>
        ))}
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Braindump Item Modal"
      >
        <div className="events_header">
          <h2>Add Braindump Item</h2>
        </div>
        <input
          type="text"
          placeholder="Enter new braindump"
          value={newBraindumpItem}
          onChange={(e) => setNewBraindumpItem(e.target.value)}
        />
        <div className="func_btns">
          <button
            className="braindump_buttond"
            onClick={handleAddBraindumpItem}
          >
            Add Braindump
          </button>
          <button className="braindump_button" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default BrainDump;
