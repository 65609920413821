import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import arraysEqual from "../../Components/Utilities/arrayEquals";

const initialState = {
  courses: [],
  course: null,
  createStatus: "",
  assignmentsAndExams: [],
  editStatus: "",
  deleteCourseStatus: "",
  getCourseByIdStatus: "",
  getCoursesStatus: "",
  getassignmentsAndExamsStatus: "",
};

export const getCoursesForUser = createAsyncThunk(
  "courseSlice/getCoursesForUser",

  async (id, { rejectWithValue }) => {
    try {
      const url = await process.env.REACT_APP_BACKEND_MAIN_SERVER_URI;
      const response = await axios.get(`${url}/course/user/${id}/courses`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getCoursesForUser : ", error.message);
    }
  }
);

export const getCourseById = createAsyncThunk(
  "courseSlice/getCourseById",

  async (courseId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/course/${courseId}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getCoursesForUser : ", error.message);
    }
  }
);

export const getAssignmentsAndExams = createAsyncThunk(
  "courseSlice/getAssignmentsAndExams",

  async (courseId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/course/${courseId}/assignmentsAndExams`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getCoursesForUser : ", error.message);
    }
  }
);

export const createNewCourse = createAsyncThunk(
  "courseSlice/createNewCourse",
  async (course, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/course/create`,
        { ...course },
        {
          withCredentials: true,
        }
      );
      if (response.status === 201) {
        return response.data;
      } else {
        rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getCoursesForUser : ", error.message);
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "courseSlice/deleteCourse",
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/course/delete/${courseId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getCoursesForUser : ", error.message);
    }
  }
);

export const updateCourse = createAsyncThunk(
  "courseSlice/updateCourse",
  async ({ course, courseId }, { rejectWithValue }) => {
    try {
      console.log(courseId);
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/course/edit/${courseId}`,
        { ...course },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getCoursesForUser : ", error.message);
    }
  }
);
const courseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoursesForUser.pending, (state, action) => {
        state.getCoursesStatus = "pending";
      })
      .addCase(getCoursesForUser.fulfilled, (state, action) => {
        if (action.payload?.courses) {
          const newCourses = action.payload.courses;

          // Check if the new courses are different from the current courses
          if (!arraysEqual(state.courses, newCourses)) {
            state.courses = newCourses;
            state.getCoursesStatus = "success";
          }
        }
      })
      .addCase(getCoursesForUser.rejected, (state, action) => {
        console.log(action.error.message);
        state.getCoursesStatus = "rejected";
      });

    builder
      .addCase(createNewCourse.pending, (state) => {
        state.createStatus = "pending";
      })
      .addCase(createNewCourse.fulfilled, (state, action) => {
        if (action.payload) {
          state.courses.push(action.payload.savedCourse);
          toast.success("Course Added SuccessFully");
        }
      })
      .addCase(createNewCourse.rejected, (state, action) => {
        toast.error(action.error.message);
        console.log(action.error.message);
        state.createStatus = "rejected";
      });

    // get course by id
    builder
      .addCase(getCourseById.pending, (state, action) => {
        return { ...state, getCourseByIdStatus: "pending" };
      })
      .addCase(getCourseById.fulfilled, (state, action) => {
        if (action.payload) {
          state.course = action.payload.course;
          state.getCourseByIdStatus = "success";
        }
      })
      .addCase(getCourseById.rejected, (state, action) => {
        console.log(action.error.message);
        return { ...state, getCourseByIdStatus: "rejected" };
      });

    // edit Course
    builder
      .addCase(updateCourse.pending, (state) => {
        return { ...state, editStatus: "pending" };
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        if (action.payload) {
          const newCourses = state.courses.map((course) => {
            return course._id === action.payload.updatedCourse._id
              ? action.payload.updatedCourse
              : course;
          });

          toast.success("Course Updated SuccessFully.");
          return {
            ...state,
            courses: newCourses,
            editStatus: "success",
          };
        }
      })
      .addCase(updateCourse.rejected, (state, action) => {
        console.log(action.error.message);
        toast.error(action.error.message);
        return { ...state, editStatus: "rejected" };
      });

    // delete Course
    builder
      .addCase(deleteCourse.pending, (state, action) => {
        return { ...state, deleteCourseStatus: "pending" };
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        if (action.payload) {
          const newCourses = state.courses.filter((course) => {
            return course._id !== action.payload.deletedCourse._id;
          });
          toast.success("Course Deleted SuccessFully.");

          return {
            ...state,
            courses: newCourses,
            deleteCourseStatus: "success",
          };
        }
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        toast.error(action.error.message);
        console.log(action.error.message);
        return { ...state, deleteCourseStatus: "rejected" };
      });

    // get assignments and exams for a course of a user
    builder
      .addCase(getAssignmentsAndExams.pending, (state, action) => {
        state.getassignmentsAndExamsStatus = "pending";
      })
      .addCase(getAssignmentsAndExams.fulfilled, (state, action) => {
        if (action.payload) {
          state.assignmentsAndExams = [...action.payload.assignmentsAndExams];

          state.getassignmentsAndExamsStatus = "success";
        }
      })
      .addCase(getAssignmentsAndExams.rejected, (state, action) => {
        console.log(action.error.message);
        state.getassignmentsAndExamsStatus = "rejected";
      });
  },
});

export const { resetState } = courseSlice.actions;
export default courseSlice.reducer;
