import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createNewAssignMent } from "../../redux/slice/assignmentSlice";
import statusArr from "../Utilities/statusArr";

const AssignmentModal = ({ closeModal }) => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const { courses } = useSelector((state) => state.course);

  const dispatch = useDispatch();

  const [assignment, setAssignment] = useState({
    name: "",
    note: "",
    type: "",
    dueDate: "2007-04-05T11:11",
    status: "",
    grade: "",
    courseId: "",
    reminder: false,
  });
  const handleAddAssignment = () => {
    if (isLoggedIn) {
      const requiredFields = ["name", "type", "dueDate", "status"];
      const missingFields = requiredFields.filter(
        (field) => assignment[field].trim() === ""
      );

      if (missingFields.length === 0) {
        // handle redux login here
        dispatch(createNewAssignMent(assignment));
        closeModal();

        setAssignment({
          name: "",
          note: "",
          type: "",
          dueDate: "2007-04-05T11:11",
          status: "",
          grade: "",
          courseId: "",
          reminder: false,
        });
      } else {
        const missingFieldsMsg = `Missing fields: ${missingFields.join(", ")}`;
        toast.error(missingFieldsMsg);
      }
    } else {
      toast.error("Please Login to continue using matcha home");
    }
  };

  const HandleInputChange = (e) => {
    setAssignment((prev) => {
      return {
        ...prev,
        [e.target.name === "reminder" ? "reminder" : e.target.name]:
          e.target.name === "reminder" ? !prev.reminder : e.target.value,
      };
    });
  };

  return (
    <div>
      <div className="events_header">
        <h2>Add Assignment</h2>

        <div className="func_btns">
          <button className="assignments_button" onClick={handleAddAssignment}>
            Add Assignment
          </button>
          <button className="assignments_button" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
      <label>
        Name:
        <input
          type="text"
          placeholder="Enter assignment name"
          value={assignment.name}
          name="name"
          onChange={HandleInputChange}
          required
        />
      </label>
      <label>
        Note:
        <input
          type="text"
          placeholder="Enter note"
          value={assignment.note}
          name="note"
          onChange={HandleInputChange}
        />
      </label>
      <label>
        Tpye:
        <input
          type="text"
          placeholder="Enter Type(E.g:Homework,Project,Research...)"
          value={assignment.type}
          name="type"
          onChange={HandleInputChange}
        />
      </label>
      <label>
        Due Date:
        <input
          type="datetime-local"
          name="dueDate"
          value={assignment.dueDate}
          onChange={HandleInputChange}
          required
        />
      </label>
      <label>
        Course:
        <select
          name="courseId"
          value={assignment.courseId}
          onChange={HandleInputChange}
        >
          <option value={""}>
            Select A Course Related To this assignment.{" "}
          </option>
          {courses.length !== 0 ? (
            courses.map((course, index) => (
              <option key={index} value={course._id}>
                {course.name}
              </option>
            ))
          ) : (
            <option>You Have'nt Created Any Courses Yet</option>
          )}
        </select>
      </label>

      <label>
        Status:
        <select
          name="status"
          value={assignment.status}
          onChange={HandleInputChange}
        >
          <option value={""}>Select A Status of your assignment. </option>
          {statusArr.length !== 0 ? (
            statusArr.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))
          ) : (
            <option>You Have'nt Created Any Courses Yet</option>
          )}
        </select>
      </label>

      <label>
        Grade:
        <input
          type="text"
          placeholder="Enter grade"
          value={assignment.grade}
          name="grade"
          onChange={HandleInputChange}
        />
      </label>
      <label className="rem_label">
        Reminder:
        <input
          type="checkbox"
          checked={assignment.reminder}
          name="reminder"
          onChange={HandleInputChange}
          required
        />
      </label>
    </div>
  );
};

export default AssignmentModal;
