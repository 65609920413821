import { useEffect } from "react";
import FlipClock from "./FlipClock";
import "./Course.css";
import courseImage from "../../Assets/Courses.jpg";
import courseimg from "../../Assets/cour.png";
// eslint-disable-next-line
import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getCourseById } from "../../../redux/slice/courseSlice";
// eslint-disable-next-line
import { toast } from "react-toastify";
import CourseAndAssTable from "./CourseAndAssignmentTable/CourseAndAssTable";

const CoursePage = () => {
  const { courseId } = useParams();
  const { course } = useSelector((store) => store.course);
  const { isLoggedIn } = useSelector((store) => store.user);

  const dispatch = useDispatch();
  useEffect(() => {
    if (courseId && isLoggedIn) {
      dispatch(getCourseById(courseId));
    }
    window.scrollTo(0, 0);
    return () => {};
  }, [courseId, dispatch, isLoggedIn]);

  if (!isLoggedIn) {
    toast.warning("User Not Logged In!");
    return <Navigate to={"/"} />;
  }

  return (
    <div>
      <div className="course_banner">
        <img src={courseImage} alt="" />
        <div className="floating_icon">
          <img src={courseimg} alt="" />
        </div>
      </div>
      <div className="courses_content">
        <div className="course_card">
          <div>
            {course && (
              <div className="course_card_content">
                <h2>{course.name}</h2>
                <p>
                  Professor:<span>{course.professor}</span>{" "}
                </p>
                <p>
                  Start Date:{" "}
                  <span>
                    {new Date(course.startDate).toLocaleString([], {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}{" "}
                  </span>
                </p>
                <p>
                  End Date:{" "}
                  <span>
                    {new Date(course.endDate).toLocaleString([], {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </span>
                </p>
                <p>
                  Classroom:<span> {course.classroom}</span>
                </p>
              </div>
            )}
          </div>
          <div className="clock_div">
            <FlipClock />
          </div>
        </div>

        <div>
          <CourseAndAssTable courseId={courseId} />
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
