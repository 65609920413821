function arraysEqual(arr1, arr2) {
  // Check if both arrays are defined
  if (!arr1 || !arr2) {
    return false;
  }

  // Check if the lengths are different
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Check each element for equality
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // Arrays are equal
  return true;
}

export default arraysEqual;
