import logo from "../Assets/cup.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import assignments from "../Assets/assignments.png";
import { PiExam } from "react-icons/pi";

import { IoLogOutOutline, IoPersonAddOutline } from "react-icons/io5";
import "./Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slice/usersSlice";
import { MdOutlineCollectionsBookmark } from "react-icons/md";
import { resetClassState } from "../../redux/slice/classSlice";
import { resetState } from "../../redux/slice/courseSlice";
import { resetAssState } from "../../redux/slice/assignmentSlice";
import { resetExamState } from "../../redux/slice/examSlice";

const Navbar = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_AUTH_SERVER_URI}/auth/google`;
  };

  const handleLogout = () => {
    // dispatch logout req
    dispatch(logout(null));

    dispatch(resetState(null));
    dispatch(resetClassState(null));
    dispatch(resetAssState(null));
    dispatch(resetExamState(null));
    navigate("/");
  };

  const location = useLocation();
  const path = location.pathname;

  const style = {
    display: "flex",
    alignItems: "center",
    gap: "0.2em",
  };

  const imgStyle = {
    width: "20px",
  };

  const getPageName = () => {
    if (path.startsWith("/course/")) {
      return (
        <div style={style}>
          / <MdOutlineCollectionsBookmark /> Course
        </div>
      );
    }
    switch (path) {
      case "/Notebook":
        return "/ Notebook";
      case "/Assignments":
        return (
          <div style={style}>
            / <img src={assignments} alt="assignment-icon" style={imgStyle} />
            Assignments
          </div>
        );
      case "/Exams":
        return (
          <div style={style}>
            / <PiExam /> Exam
          </div>
        );
      case "/Course":
        return (
          <div style={style}>
            / <MdOutlineCollectionsBookmark /> Courses
          </div>
        );

      case "/Classes":
        return (
          <div style={style}>
            / <img src={assignments} alt="classes-icon" style={imgStyle} />
            Classes
          </div>
        );
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="nav">
        <div className="nav_path">
          <Link to="/">
            <div className="logo">
              <div className="logo_img">
                <img src={logo} alt="matcha-home-logo" />
              </div>
              <p>Matcha home</p>
            </div>
          </Link>
          <Link to={path === "/Courseid" ? "#" : path}>
            <div className="page_name">
              <>{getPageName()}</>
            </div>
          </Link>
        </div>
        <div className="nav_buttons">
          <div className="login_btn">
            {isLoggedIn ? (
              // Render logout button if the user is logged in
              <button onClick={handleLogout}>
                <IoLogOutOutline /> Logout
              </button>
            ) : (
              // Render login button if the user is not logged in
              <button onClick={handleLogin}>
                <IoPersonAddOutline /> Login
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
