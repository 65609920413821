import { useCallback, useEffect, useState } from "react";
import "./Course.css";
import "./FlipClock.css";

const FlipClock = () => {
  // eslint-disable-next-line
  const [time, setTime] = useState(new Date());

  const flipTo = useCallback((digit, n) => {
    const current = digit.getAttribute("data-num");
    digit.setAttribute("data-num", n);
    digit.querySelector(".front").setAttribute("data-content", current);
    digit
      .querySelectorAll(".back, .under")
      .forEach((flap) => flap.setAttribute("data-content", n));
    digit
      .querySelectorAll(".flap")
      .forEach((flap) => (flap.style.display = "block"));
    setTimeout(() => {
      digit.querySelector(".base").textContent = n;
      digit
        .querySelectorAll(".flap")
        .forEach((flap) => (flap.style.display = "none"));
    }, 350);
  }, []);

  const jumpTo = useCallback((digit, n) => {
    digit.setAttribute("data-num", n);
    digit.querySelector(".base").textContent = n;
  }, []);

  const updateGroup = useCallback(
    (group, n, flip) => {
      const digit1 = document.querySelector(`.ten${group}`);
      const digit2 = document.querySelector(`.${group}`);
      n = String(n);
      if (n.length === 1) n = "0" + n;
      const num1 = n.substr(0, 1);
      const num2 = n.substr(1, 1);
      if (digit1.getAttribute("data-num") !== num1) {
        if (flip) flipTo(digit1, num1);
        else jumpTo(digit1, num1);
      }
      if (digit2.getAttribute("data-num") !== num2) {
        if (flip) flipTo(digit2, num2);
        else jumpTo(digit2, num2);
      }
    },
    [flipTo, jumpTo]
  );
  const updateTime = useCallback(() => {
    const t = new Date();
    setTime(t);
    updateGroup("hour", t.getHours(), true);
    updateGroup("min", t.getMinutes(), true);
    updateGroup("sec", t.getSeconds(), true);
  }, [updateGroup]);

  useEffect(() => {
    updateTime();
    const intervalId = setInterval(() => {
      updateTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [updateTime]);

  useEffect(() => {
    updateTime();
    const intervalId = setInterval(() => {
      updateTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [updateTime]);

  return (
    <div>
      <div className="clock">
        <div className="digit tenhour">
          <span className="base"></span>
          <div className="flap over front"></div>
          <div className="flap over back"></div>
          <div className="flap under"></div>
        </div>

        <div className="digit hour">
          <span className="base"></span>
          <div className="flap over front"></div>
          <div className="flap over back"></div>
          <div className="flap under"></div>
        </div>

        <div className="digit tenmin">
          <span className="base"></span>
          <div className="flap over front"></div>
          <div className="flap over back"></div>
          <div className="flap under"></div>
        </div>

        <div className="digit min">
          <span className="base"></span>
          <div className="flap over front"></div>
          <div className="flap over back"></div>
          <div className="flap under"></div>
        </div>

        <div className="digit tensec">
          <span className="base"></span>
          <div className="flap over front"></div>
          <div className="flap over back"></div>
          <div className="flap under"></div>
        </div>

        <div className="digit sec">
          <span className="base"></span>
          <div className="flap over front"></div>
          <div className="flap over back"></div>
          <div className="flap under"></div>
        </div>
      </div>
    </div>
  );
};

export default FlipClock;
