import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Layout = () => {
  const { username, isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    if (isLoggedIn) {
      toast(`Welcome ${username}`);
    }
    return () => {};
  }, [isLoggedIn, username]);

  return (
    <div className="app__main__containerp">
      <Navbar />
      <Outlet />
    </div>
  );
};

export default Layout;
