import Modal from "react-modal";
import "./Modal.css";

Modal.setAppElement(document.getElementById("root"));

const ModalComponent = ({ isOpen, onRequestClose, contentLabel, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className="goal_modal"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          zIndex: 9999,
        },
        content: {
          zIndex: 9,
          position: "absolute",
          top: "50%",
          left: "50%",
          bottom: "auto",
          right: "auto",
          width: "500px",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgb(21, 19, 24)",
          color: "snow",
          border: "1px solid #ccc",
          padding: "2em",
          outline: "none",
          overflowX: "hidden",
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
