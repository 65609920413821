import { useState } from "react";
import ModalComponent from "../../../Modal/ModalComponent";
import { useSelector } from "react-redux";
import UpdateAssignmentModal from "../../../AssignmentModal/UpdateAssignmentModal";

const EditAssignment = ({ id }) => {
  const { displayAssignMents } = useSelector((store) => store.assignment);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [assignment, setAssignment] = useState({
    name: "",
    note: "",
    type: "",
    dueDate: "2007-04-05T11:11",
    status: "",
    grade: "",
    courseId: "",
    reminder: false,
  });

  const openEditModal = () => {
    const selectedAssignment = displayAssignMents?.find((exam) => {
      return exam._id === id;
    });

    if (selectedAssignment) {
      setAssignment((prev) => ({
        ...prev,
        ...selectedAssignment,
        courseId: selectedAssignment.courseId?._id,
        dueDate: selectedAssignment.dueDate.slice(0, -1),
      }));
    }
    setIsEditModalOpen(true);
  };
  const closeEditModal = () => setIsEditModalOpen(false);

  return (
    <div>
      <button onClick={openEditModal}>Edit</button>
      <ModalComponent isOpen={isEditModalOpen} onRequestClose={closeEditModal}>
        <UpdateAssignmentModal
          closeEditModal={closeEditModal}
          selectedAss={assignment}
        />
      </ModalComponent>
    </div>
  );
};

export default EditAssignment;
