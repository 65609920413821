import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import arraysEqual from "../../Components/Utilities/arrayEquals";

const initialState = {
  displayExams: [],
  exam: null,
  createStatus: "",
  editStatus: "",
  deleteStatus: "",
  getExamByIdStatus: "",
  getExamsStatus: "",
};

export const getExamsForUser = createAsyncThunk(
  "examSlice/getexamsForUser",
  async (id, { rejectWithValue }) => {
    try {
      const url = await process.env.REACT_APP_BACKEND_MAIN_SERVER_URI;
      const response = await axios.get(`${url}/exam/user/${id}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getAssingMentsForUser : ", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getExamById = createAsyncThunk(
  "examSlice/getexamById",

  async (examId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/exam/${examId}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getExamById : ", error.message);
    }
  }
);
export const createNewExam = createAsyncThunk(
  "examSlice/createNewExam",
  async (newexam, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/exam/create`,
        { ...newexam },
        { withCredentials: true }
      );

      if (response.status === 201) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In creating new exam: ", error.message);
      return rejectWithValue("Failed to create exam");
    }
  }
);

export const editExam = createAsyncThunk(
  "examSlice/editExam",
  async ({ exam, examId }) => {
    try {
      if (examId) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/exam/edit/${examId}`,
          exam,
          {
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          return response.data;
        } else {
          // return rejectWithValue(response.data.message);
        }
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
    }
  }
);

export const deleteExam = createAsyncThunk(
  "examSlice/deleteExam",
  async (examId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_MAIN_SERVER_URI}/exam/delete/${examId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      console.log("Error In getclasssForUser : ", error.message);
      return rejectWithValue(error.message);
    }
  }
);

const examSlice = createSlice({
  name: "examSlice",
  initialState,
  reducers: {
    resetExamState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // get classes for user
    builder
      .addCase(getExamsForUser.pending, (state) => {
        state.getExamsStatus = "pending";
      })
      .addCase(getExamsForUser.fulfilled, (state, action) => {
        if (action.payload) {
          const newExams = [...action.payload.exams];

          // Check if the new exams are different from the current exams
          if (!arraysEqual(state.displayExams, newExams)) {
            state.displayExams = newExams;
            state.getExamsStatus = "success";
          }
        }
      })
      .addCase(getExamsForUser.rejected, (state, action) => {
        console.log(action.error.message);
        state.getExamsStatus = "rejected";
      });

    // get exam by id for a user
    builder
      .addCase(getExamById.pending, (state) => {
        return { ...state, getExamByIdStatus: "pending" };
      })
      .addCase(getExamById.fulfilled, (state, action) => {
        if (action.payload) {
          state.exam = action.payload.exam;
          state.getExamByIdStatus = "success";
        }
      })
      .addCase(getExamById.rejected, (state, action) => {
        console.log(action.error.message);
        toast.error(action.error.message);
        return { ...state, getExamByIdStatus: "rejected" };
      });

    // create new exam
    builder
      .addCase(createNewExam.pending, (state) => {
        state.createStatus = "pending";
      })
      .addCase(createNewExam.fulfilled, (state, action) => {
        if (action.payload) {
          state.createStatus = "success";
          state.displayExams = [
            ...state.displayExams,
            action.payload.savedExam,
          ];
          toast.success("exam Created Successfully.");
        }
      })
      .addCase(createNewExam.rejected, (state, action) => {
        console.log(action.error.message);
        toast.error(action.error.message);
        state.createStatus = "rejected";
      });

    // edit exam
    builder
      .addCase(editExam.pending, (state) => {
        return { ...state, editStatus: "pending" };
      })
      .addCase(editExam.fulfilled, (state, action) => {
        if (action.payload) {
          const newExams = state.displayExams.map((exam) => {
            return exam._id === action.payload.updatedExam._id
              ? action.payload.updatedExam
              : exam;
          });

          toast.success("Exam Updated SuccessFully.");
          return {
            ...state,
            displayExams: newExams,
            editStatus: "success",
          };
        }
      })
      .addCase(editExam.rejected, (state, action) => {
        console.log(action.error.message);
        toast.error(action.error.message);
        return { ...state, editStatus: "rejected" };
      });

    // delete exam
    builder
      .addCase(deleteExam.pending, (state) => {
        return { ...state, deleteStatus: "pending" };
      })
      .addCase(deleteExam.fulfilled, (state, action) => {
        if (action.payload) {
          const newExams = state.displayExams.filter((ass) => {
            return ass._id !== action.payload.deletedExam._id;
          });

          toast.success("exam Deleted SuccessFully.");
          return {
            ...state,
            displayExams: newExams,
            deleteStatus: "success",
          };
        }
      })
      .addCase(deleteExam.rejected, (state, action) => {
        console.log(action.error.message);
        toast.error(action.error.message);
        return { ...state, deleteStatus: "rejected" };
      });
  },
});

export const { resetExamState } = examSlice.actions;

export default examSlice.reducer;
