import { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import ModalComponent from "../../Modal/ModalComponent";
import classImage from "../../Assets/class.png";
import assignment from "../../Assets/assignments.png";
import "./Class.css";
import ClassModal from "../../classModal/ClassModal";
import ClassOnDays from "../../Section-1/classOnDays";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ClassPage = () => {
  const { isLoggedIn } = useSelector((store) => store.user);

  const [isClassModalOpen, setIsClassModalOpen] = useState(false);

  const openClassModal = () => setIsClassModalOpen(true);
  const closeClassModal = () => setIsClassModalOpen(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  if (!isLoggedIn) {
    toast.warning("User Not Logged In!");
    return <Navigate to={"/"} />;
  }
  

  return (
    <div>
      <div className="class_banner">
        <img src={classImage} alt="" />
      </div>
      <div className="classes_content">
        <div className="class_header">
          <div className="main_title">
            <img src={assignment} alt="" loading="lazy" />
            <p>Classes</p>
          </div>

          <div className="add_class" onClick={openClassModal}>
            <MdAddCircleOutline />
            <p>Add Class</p>
          </div>
        </div>

        <div className="class_content">
          <ClassOnDays
            enableStrikethrough={false}
            additionalClass="class_delete"
            strikethroughClass=""
          />
        </div>
      </div>

      <ModalComponent
        isOpen={isClassModalOpen}
        onRequestClose={closeClassModal}
      >
        <ClassModal closeModal={closeClassModal} />
      </ModalComponent>
    </div>
  );
};

export default ClassPage;
