import React from 'react'
import './ErrorPage.css'

const AuthError = () => {
  return (
    <div className='error_page'>
        <div className="hollowtext user_error">Authentication Error <hr /> <span>User not Authenticated</span></div>
    </div>
  )
}

export default AuthError