import { useEffect, useState } from "react";
import "./Assignment.css";
import assignbanner from "../../Assets/assignments.gif";
import assignments from "../../Assets/assignments.png";
import Table from "../Table/Table";
import { MdAddCircleOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAssignment,
  getAssignMentsForUser,
} from "../../../redux/slice/assignmentSlice";
import { getCoursesForUser } from "../../../redux/slice/courseSlice";
import { FaCircle } from "react-icons/fa";

import getColorForCourseId from "../../Utilities/getColorsForCourseId";
import EditAssignment from "./EditAssignment/EditAssignment";
import ModalComponent from "../../Modal/ModalComponent";
import AssignmentModal from "../../AssignmentModal/AssignmentModal";

import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
const Assignments = () => {
  const dispatch = useDispatch();

  const { _id, isLoggedIn } = useSelector((store) => store.user);

  const { displayAssignMents } = useSelector((store) => store.assignment);

  const data = displayAssignMents || [];

  useEffect(() => {
    if (_id && isLoggedIn) {
      dispatch(getAssignMentsForUser(_id));

      if (_id && isLoggedIn) {
        dispatch(getCoursesForUser(_id));
      }
    }
    window.scrollTo(0, 0);
    return () => {};
  }, [_id, dispatch, isLoggedIn]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDelete = (_id) => {
    dispatch(deleteAssignment(_id));
  };

  const rows =
    data &&
    data.map((ass) => {
      return {
        id: ass._id,
        name: ass.name,
        type: ass.type,
        course: ass.courseId ? ass.courseId.name : "N/A",
        dueDate: new Date(ass.dueDate).toLocaleString([], {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",

          hour12: true,
        }),
        status: ass.status,
        note: ass.note,
        grade: ass.grade,
        reminder: ass.reminder,
      };
    });

  const columns = [
    { field: "id", headerName: "ID", width: 200, hideAble: true },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "type",
      headerName: "Type",
      width: 120,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor: getColorForCourseId(params.row.type),
            borderRadius: "5px",
            padding: "0.3em",
          }}
        >
          {params.row.type}
        </div>
      ),
    },
    {
      field: "course",
      headerName: "Course",
      width: 150,
      renderCell: (params) => (
        <div
          style={
            params.row.course === "N/A" || !params.row.course
              ? {}
              : {
                  backgroundColor: getColorForCourseId(params.row.course),
                  borderRadius: "5px",
                  padding: "0.3em",
                }
          }
        >
          {params.row.course ? params.row.course : "N/A"}
        </div>
      ),
    },
    { field: "dueDate", headerName: "DueDate", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 150,

      renderCell: (params) => (
        <div className={params.row.status.replace(" ", "")}>
          <FaCircle size={30} />
          {params.row.status}
        </div>
      ),
    },
    { field: "note", headerName: "Note", width: 200 },
    { field: "grade", headerName: "Grade", width: 120 },
    {
      field: "reminder",
      headerName: "Reminder",
      width: 150,
      renderCell: (params) => (
        <div>
          <p>{params.row.reminder ? "ON" : "OFF"}</p>
        </div>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 1,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      filterable: false,

      renderCell: (params) => (
        <div className="action_btns">
          <EditAssignment id={params.row.id} />

          <button name="delete" onClick={() => handleDelete(params.row.id)}>
            Delete
          </button>
        </div>
      ),
    },
  ];

  if (!isLoggedIn) {
    toast.warning("User Not Logged In!");
    return <Navigate to={"/"} />;
  }
  return (
    <div>
      <div className="assign_banner">
        <img src={assignbanner} alt="" />
      </div>

      <div className="assign_content">
        <div className="assign_title">
          <div className="main_title">
            <img src={assignments} alt="" />
            Assignments
          </div>

          <div className="">
            <div className="add_assign" onClick={openModal}>
              <MdAddCircleOutline />
              <p>Add Assignment</p>
            </div>
          </div>
        </div>
        {rows && columns && (
          <div className="table">
            <Table rows={rows} columns={columns} />
          </div>
        )}

        <ModalComponent isOpen={isModalOpen} onRequestClose={closeModal}>
          <AssignmentModal closeModal={closeModal} />
        </ModalComponent>
      </div>
    </div>
  );
};

export default Assignments;
