import { Link } from "react-router-dom";

import "./Pages.css";
import { PiExam } from "react-icons/pi";
import {
  MdOutlineAssignment,
  MdOutlineCollectionsBookmark,
} from "react-icons/md";
const Pages = () => {
  return (
    <div className="pages">
      <h1>Pages</h1>

      <div className="pages_content">
        <div className="notebook">
          <Link to="/Course">
            <MdOutlineCollectionsBookmark size={30} />
            <p>Courses</p>
          </Link>
        </div>
        <div className="assignments">
          <Link to="/Assignments">
            <MdOutlineAssignment size={32} />
            <p>Assignments</p>
          </Link>
        </div>
        <div className="exams">
          <Link to="/Exams">
            <PiExam />
            <p>Exams</p>
          </Link>
        </div>
        <div className="exams">
          <Link to="/Classes">
            <PiExam />
            <p>Classes</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Pages;
