import { useState } from "react";
import UpdateExamModal from "../../../ExamModal/UpdateExamModal";
import ModalComponent from "../../../Modal/ModalComponent";
import { useSelector } from "react-redux";

const EditExam = ({ id }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { displayExams } = useSelector((store) => store.exam);
  const [exam, setExam] = useState({
    name: "",
    note: "",
    examDate: "2007-04-05T11:11",
    courseId: "",
    grade: "",
    reminder: false,
  });
  const openEditModal = () => {
    const selectedExam = displayExams?.find((exam) => {
      return exam._id === id;
    });

    if (selectedExam) {
      setExam((prev) => ({
        ...prev,
        ...selectedExam,
        courseId: selectedExam.courseId?._id,
        examDate: selectedExam.examDate.slice(0, -1),
      }));
    }
    setIsEditModalOpen(true);
  };
  const closeEditModal = () => setIsEditModalOpen(false);

  return (
    <div>
      <button onClick={openEditModal}>Edit</button>
      <ModalComponent isOpen={isEditModalOpen} onRequestClose={closeEditModal}>
        <UpdateExamModal closeModal={closeEditModal} selectedExam={exam} />
      </ModalComponent>
    </div>
  );
};

export default EditExam;
