import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import daysAndEmojis from "../Utilities/daysAndEmojis";
import { createNewClass } from "../../redux/slice/classSlice";
import convertTimeTo12HourFormat from "../Utilities/time12HourFormat";
import { toast } from "react-toastify";

const ClassModal = ({ closeModal }) => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const { courses } = useSelector((state) => state.course);
  const dispatch = useDispatch();

  const [classObj, setClassObj] = useState({
    name: "",
    dayOfWeek: "",
    startTime: "11:11",
    endTime: "11:11",
    courseId: "",
    userId: "",
  });

  const HandleInputChange = (e) => {
    setClassObj((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const HandleAddClass = () => {
    if (isLoggedIn) {
      if (
        classObj.name.trim() !== "" &&
        classObj.dayOfWeek.trim() !== "" &&
        classObj.startTime.trim() !== "" &&
        classObj.endTime.trim() !== "" &&
        classObj.courseId.trim() !== ""
      ) {
        classObj.startTime = convertTimeTo12HourFormat(classObj.startTime);
        classObj.endTime = convertTimeTo12HourFormat(classObj.endTime);

        dispatch(createNewClass(classObj));

        closeModal();
        setClassObj({
          name: "",
          dayOfWeek: "",
          startTime: "11:11",
          endTime: "11:11",
          courseId: "",
          userId: "",
        });
      } else {
        let missingFields = [];

        if (classObj.name.trim() === "") {
          missingFields.push("Class Name");
        }
        if (classObj.dayOfWeek.trim() === "") {
          missingFields.push("Day of Week");
        }
        if (classObj.startTime.trim() === "") {
          missingFields.push("Start Time");
        }
        if (classObj.endTime.trim() === "") {
          missingFields.push("End Time");
        }
        if (classObj.courseId.trim() === "") {
          missingFields.push("Course Name");
        }
        const missingFieldsMsg = `Missing fields: ${missingFields.join(", ")}`;
        toast.error(missingFieldsMsg);
      }
    } else {
      toast.error("Please Login to continue using matcha home");
    }
  };

  return (
    <div>
      {" "}
      <div className="addclass_header">
        <h2>Add Classes</h2>
        <div className="func_btns">
          <button onClick={HandleAddClass}>Add Class</button>
          <button onClick={closeModal}>Cancel</button>
        </div>
      </div>
      <form>
        <label>
          Day Of Week:
          <select
            name="dayOfWeek"
            value={classObj.dayOfWeek}
            required
            onChange={HandleInputChange}
          >
            <option value={""}>Select A Day Of Week </option>
            {daysAndEmojis.map(({ emoji, day }, index) => (
              <option key={index} value={day}>
                {`${emoji} ${day}`}
              </option>
            ))}
          </select>
        </label>
        <label>
          Start-Time:
          <input
            type="time"
            name="startTime"
            value={classObj.startTime}
            onChange={HandleInputChange}
            required
          />
        </label>
        <label>
          End-Time:
          <input
            type="time"
            name="endTime"
            value={classObj.endTime}
            onChange={HandleInputChange}
            required
          />
        </label>
        <label>
          Class:
          <input
            type="text"
            name="name"
            value={classObj.name}
            onChange={HandleInputChange}
            required
          />
        </label>
        <label>
          Course Name:
          <select
            name="courseId"
            value={classObj.courseId}
            onChange={HandleInputChange}
            required
          >
            <option value={""}>Select A Course Related To this class. </option>

            {courses.length !== 0 ? (
              courses.map((course, index) => (
                <option key={index} value={course._id}>
                  {course.name}
                </option>
              ))
            ) : (
              <option>You Have'nt Created Any Courses Yet</option>
            )}
          </select>
        </label>
        <label>
          Classroom:
          <input
            type="text"
            name="classroom"
            value={classObj.classroom}
            onChange={HandleInputChange}
          />
        </label>
      </form>
    </div>
  );
};

export default ClassModal;
