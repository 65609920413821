import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createNewCourse } from "../../redux/slice/courseSlice";

const CourseModal = ({ closeModal }) => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [course, setCourse] = useState({
    name: "",
    professor: "",
    classroom: "",
    startDate: "2007-04-05",
    endDate: "2007-04-05",
  });

  const HandleInputChange = (e) => {
    setCourse((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleAddCourse = () => {
    if (isLoggedIn) {
      if (course.name.trim() !== "" && course.professor.trim() !== "") {
        dispatch(createNewCourse(course));

        closeModal();
        setCourse({
          name: "",
          professor: "",
          classroom: "",
          startDate: "2007-04-05",
          endDate: "2007-04-05",
        });
      } else {
        let missingFields = [];
        if (course.name.trim() === "") {
          missingFields.push("Course Name");
        }
        if (course.professor.trim() === "") {
          missingFields.push("Professor's Name");
        }
        const missingFieldsMsg = `Missing fields: ${missingFields.join(", ")}`;
        toast.error(missingFieldsMsg);
      }
    } else {
      toast.error("Please Login to continue using matcha home");
    }
  };

  return (
    <div>
      <div className="events_header">
        <h2>Add Course</h2>
        <div className="func_btns">
          <button onClick={handleAddCourse}>Add Course</button>
          <button onClick={closeModal}>Cancel</button>
        </div>
      </div>
      <label>
        Name:
        <input
          type="text"
          placeholder="name"
          name="name"
          required
          value={course.name}
          onChange={HandleInputChange}
        />
      </label>
      <label>
        Professor Name:
        <input
          type="text"
          placeholder="Professor"
          name="professor"
          value={course.professor}
          onChange={HandleInputChange}
        />
      </label>
      <label>
        Classroom:
        <input
          type="text"
          placeholder="Classroom"
          name="classroom"
          value={course.classroom}
          onChange={HandleInputChange}
        />
      </label>
      <div className="time">
        <label>
          Start Date:
          <input
            type="date"
            name="startDate"
            placeholder="Start Date"
            value={course.startDate}
            onChange={HandleInputChange}
            required
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            placeholder="End Date"
            name="endDate"
            value={course.endDate}
            onChange={HandleInputChange}
            required
          />
        </label>
      </div>
    </div>
  );
};

export default CourseModal;
