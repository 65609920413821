const emoji = [
  "💀",
  "💩",
  "🍵",
  "🍭",
  "☕️",
  "🍶",
  "🥤",
  "🌳",
  "🌴",
  "🦥",
  "🎄",
  "🐲",
  "🐉",
];

const getRandomEmoji = () => {
  const randomIndex = Math.floor(Math.random() * emoji.length);
  return `${emoji[randomIndex]}   `;
};

module.exports = getRandomEmoji;
