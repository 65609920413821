import { useState } from "react";
import logo from "../Assets/cup.svg";
import banner from "../Assets/766368.jpg";
import hero_img_1 from "../Assets/heroimg1.JPG";
import day from "../Assets/exam_banner.png";
import "./Section_1.css";
import { MdAddCircleOutline } from "react-icons/md";
import Courses from "./Courses/CoursesList";
import ModalComponent from "../Modal/ModalComponent";

import ClassModal from "../classModal/ClassModal";
import ClassOnDays from "./classOnDays";

const Section1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="section-1">
      <div className="banner">
        <img src={banner} alt="" />
        <div className="cup">
          <img src={logo} alt="" />
        </div>
      </div>

      <div className="title">
        <h1>Matcha Home</h1>
      </div>
      <div className="grid_container">
        <div className="grid_item column_1">
          <div className="hero_img_1">
            <img src={hero_img_1} alt="" />
          </div>

          <Courses />
        </div>
        <div className="grid_item column_2">
          <div className="todo_form">
            <div className="add_todo" onClick={openModal}>
              <MdAddCircleOutline />
              <p>Add Classes</p>
            </div>
            <ModalComponent isOpen={isModalOpen} onRequestClose={closeModal}>
              <ClassModal closeModal={closeModal} />
            </ModalComponent>
          </div>
          <ClassOnDays
            enableStrikethrough={true}
            additionalClass=""
            strikethroughClass="strikethrough"
          />
          <div className="day_card img_container">
            <img src={day} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
