import { useState, useEffect } from "react";
import { MdAddCircleOutline, MdDelete } from "react-icons/md";
import ModalComponent from "../../Modal/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCourse,
  getCoursesForUser,
} from "../../../redux/slice/courseSlice";
import { Link } from "react-router-dom";
import CourseModal from "../../CourseModal/courseModal";
import getRandomEmoji from "../../Utilities/getRandomEmoji";

const Courses = () => {
  const { courses } = useSelector((state) => state.course);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { _id, isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && _id) dispatch(getCoursesForUser(_id));

    return () => {};
  }, [_id, dispatch, isLoggedIn]);

  const handleDeleteCourse = (courseId) => {
    dispatch(deleteCourse(courseId));
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="courses">
      <h1>
        Courses
        <button className="add_courses" onClick={openModal}>
          <MdAddCircleOutline />
        </button>
      </h1>

      <div className="courses_scroll">
        {courses.length > 0
          ? courses.map((course, index) => (
              <div key={index} className="course_list">
                <Link to={`course/${course._id}`}>
                  <p>
                    {getRandomEmoji()}
                    {course.name}
                  </p>
                </Link>
                <button onClick={() => handleDeleteCourse(course._id)}>
                  <MdDelete />
                </button>
              </div>
            ))
          : ""}
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Course Modal"
      >
        <CourseModal closeModal={closeModal} />
      </ModalComponent>
    </div>
  );
};

export default Courses;
