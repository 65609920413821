// Function to generate a deterministic matte color for a given course ID
function getDeterministicColorForCourseId(courseId) {
  // Combine the course ID and the string value
  const combinedString = courseId + "YourSecretSalt";

  // Simple hash function
  let hash = 0;
  for (let i = 0; i < combinedString.length; i++) {
    hash = combinedString.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Map the hash to a value within the desired range
  const minValue = 0; // Minimum value of the range
  const maxValue = 360; // Maximum value of the range

  const normalizedHash =
    ((hash & 0x00ffffff) % (maxValue - minValue)) + minValue;

  // Convert the normalized hash to an HSL color with matte values
  const color = `hsl(${normalizedHash}, 30%, 60%)`;

  return color;
}

export default getDeterministicColorForCourseId;
