import { useState, useEffect } from "react";
import { MdAddCircleOutline, MdDelete } from "react-icons/md";
import ModalComponent from "../Modal/ModalComponent";
import "./Section_2.css";
import { FaArrowTurnUp } from "react-icons/fa6";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Goals = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const [goals, setGoals] = useState([]);
  const [newGoal, setNewGoal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAddedDiv, setShowAddedDiv] = useState(true);

  useEffect(() => {
    if (isLoggedIn) {
      const storedGoals = JSON.parse(localStorage.getItem("goals")) || [];
      setGoals(storedGoals);
      setShowAddedDiv(storedGoals.length === 0);
    } else {
      setGoals([]);
    } // Update showAddedDiv based on stored goals
  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.setItem("goals", JSON.stringify(goals));
    setShowAddedDiv(goals.length === 0); // Update showAddedDiv based on current goals
  }, [goals]);

  const handleAddGoal = () => {
    if (isLoggedIn) {
      if (newGoal.trim() !== "" && goals.length <= 3) {
        setGoals([...goals, newGoal]);
        setNewGoal("");
        closeModal();
        setShowAddedDiv(false);
      }
    } else {
      toast.error("Please Login to continue using matcha home");
    }
  };

  const handleDeleteGoal = (index) => {
    const updatedGoals = goals.filter((_, i) => i !== index);
    setGoals(updatedGoals);
    setShowAddedDiv(updatedGoals.length === 0); // Update showAddedDiv based on updated goals
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const isAddButtonDisabled = goals.length >= 3;

  return (
    <div>
      <h1>
        Goals{" "}
        {!isAddButtonDisabled && (
          <button
            className="add_goals"
            onClick={openModal}
            disabled={isAddButtonDisabled}
          >
            <MdAddCircleOutline />
          </button>
        )}
      </h1>
      {showAddedDiv && (
        <div className="box add_btn">
          <p>
            Add your Goals here{" "}
            <span>
              <FaArrowTurnUp />
            </span>
          </p>
        </div>
      )}
      <div className="goal_scroll">
        {goals.map((goal, index) => (
          <div key={index} className="box">
            <p>{goal}</p>
            <button onClick={() => handleDeleteGoal(index)}>
              <MdDelete />
            </button>
          </div>
        ))}
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Goal Modal"
      >
        <div className="events_header">
          <h2>Add Goal</h2>
        </div>
        <input
          type="text"
          placeholder="Enter new goal"
          value={newGoal}
          onChange={(e) => setNewGoal(e.target.value)}
        />
        <div className="func_btns">
          <button className="goals_button" onClick={handleAddGoal}>
            Add Goal
          </button>
          <button className="goals_button" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default Goals;
